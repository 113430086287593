<template>
  <i class="tw-icon icon">
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 1.54405C15.4116 1.79787 14.7713 1.98054 14.1118 2.05169C14.7965 1.64488 15.3093 1.00219 15.5539 0.244209C14.9113 0.626451 14.2074 0.894485 13.4734 1.03642C13.1666 0.708435 12.7955 0.447146 12.3833 0.268836C11.9711 0.0905261 11.5266 -0.000984082 11.0775 7.98059e-06C9.26043 7.98059e-06 7.79906 1.47291 7.79906 3.28039C7.79906 3.5342 7.82983 3.78802 7.87982 4.03222C5.159 3.88993 2.73236 2.59008 1.1191 0.599936C0.825142 1.10202 0.671099 1.6737 0.672996 2.25551C0.672996 3.39383 1.25177 4.39756 2.13436 4.98787C1.61424 4.96739 1.1063 4.82443 0.651845 4.57062V4.61099C0.651845 6.20503 1.77863 7.52603 3.28038 7.82984C2.99841 7.90308 2.70834 7.94055 2.41702 7.94137C2.20358 7.94137 2.00168 7.92022 1.79786 7.89137C2.2132 9.19122 3.42267 10.1353 4.86288 10.1661C3.73609 11.0487 2.32472 11.5679 0.792213 11.5679C0.517246 11.5679 0.26343 11.5582 0 11.5275C1.45367 12.4601 3.17846 12.9985 5.03593 12.9985C11.066 12.9985 14.3656 8.0029 14.3656 3.66688C14.3656 3.52459 14.3656 3.3823 14.356 3.24001C14.9944 2.77275 15.5539 2.19398 16 1.54405Z"
        fill="#2A3742"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: "TWIcon",
};
</script>
