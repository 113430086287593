<template>
  <i class="fb-icon icon">
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.39196 16V8.71352H7.85012L8.21551 5.86063H5.39196V4.04345C5.39196 3.22021 5.62133 2.65657 6.80285 2.65657H8.29997V0.113057C7.57154 0.0349924 6.83934 -0.00270088 6.10674 0.000150394C3.93395 0.000150394 2.44216 1.32658 2.44216 3.76163V5.85529H0V8.70819H2.4475V16H5.39196Z"
        fill="#2A3742"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: "FBIcon",
};
</script>
