<template>
  <main class="main">
    <article class="container" v-if="article">
      <BreadcrumbsComponent :title="article.title" v-if="article.title" />
      <div class="article">
        <div class="article__content">
          <EditorJSComponent :text="JSON.parse(article.text)" v-if="article.text" />
          <div class="article__info">
            <div class="article__info__published">
              Опубликовано:
              <time :time="article.date_publication | robotDate"
                >{{ article.date_publication | formatDate }}
              </time>
            </div>
            <div class="article__info__share" v-if="article && article.title && article.description">
              <span>Поделиться:</span>
              <div class="article__info__share__buttons">
                <ShareNetwork
                  :url="url"
                  :description="article.description"
                  :media="getImg(article)"
                  network="vk"
                  :title="article.title"
                >
                  <VKIcon />
                </ShareNetwork>
                <ShareNetwork
                  :url="url"
                  :description="article.description"
                  network="facebook"
                  :title="article.title"
                >
                  <FBIcon />
                </ShareNetwork>
                <ShareNetwork :url="url" network="twitter" :title="article.title">
                  <TWIcon />
                </ShareNetwork>
                <ShareNetwork
                  :url="url"
                  :description="article.description"
                  network="email"
                  :title="article.title"
                >
                  <ShareIcon />
                </ShareNetwork>
              </div>
            </div>
          </div>
        </div>
        <aside class="article__aside" v-if="news.length">
          <router-link
            :to="{
              name: $route.name === 'article' ? 'article' : 'press_article',
              params: { id: article.id, url: article.link },
            }"
            class="news__list__item no-first"
            v-for="(article, index) in news"
            :key="index"
          >
            <time class="news__list__date" :time="article.date_publication | robotDate"
              >{{ article.date_publication | formatDate }}
            </time>
            <h2 class="news__list__title">{{ article.title }}</h2>
            <p class="news__list__desc">{{ article.description }}</p>
            <ArrowIcon />
          </router-link>
        </aside>
      </div>
    </article>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import ArrowIcon from "components/svg/Arrow.vue";
import VKIcon from "components/svg/VK.vue";
import FBIcon from "components/svg/FB.vue";
import TWIcon from "components/svg/TW.vue";
import ShareIcon from "components/svg/Share.vue";

export default {
  name: "ArticlePage",
  async asyncData({ store, route, link }) {
    store.state.alternateLink = link;
    await store.dispatch(
      route.name === "article" ? "GET_ARTICLE_PAGE" : "GET_PRESS_ARTICLE_PAGE",
      route.params.url
    );
  },
  computed: {
    url() {
      return this.$store.state.api + this.$route.fullPath;
    },
    news() {
      if (
        this.$store.state.article_page &&
        this.$store.state.article_page.news &&
        this.$store.state.article_page.news.length
      ) {
        return this.$store.state.article_page.news;
      } else {
        return [];
      }
    },
    article() {
      if (this.$store.state.article_page && this.$store.state.article_page.article) {
        return this.$store.state.article_page.article;
      } else {
        return {};
      }
    },
  },
  /**
   * Чтобы очистить загруженную информацию для устранения
   * непредвиденных последствий
   */
  beforeRouteLeave(to, from, next) {
    this.$store.state.article_page = {};
    next();
  },
  methods: {
    getImg(article) {
      if (article.head_img && article.head_img.img && article.head_img.img.url) {
        return this.$store.state.api + article.head_img.img.url;
      }
    },
  },
  metaInfo() {
    let path = this.$route.fullPath;
    let api = this.$store.state.api;
    // let metaParent = this.$route.meta.parent[0];

    let alternateLink = this.$store.state.alternateLink;
    let article = {};
    if (this.$store.state.article_page && this.$store.state.article_page.article) {
      article = this.$store.state.article_page.article;
    }
    const title = article.meta_title || article.title;
    let img = this.getImg(article) || "";

    let parentMeta = this.$route.meta.parent.map((item, i) => {
      return {
        "@type": "ListItem",
        position: i + 2,
        name: item.title,
        item:
          api +
          "/" +
          this.$router.resolve({
            name: item.name,
            params: item.params,
          }).href,
      };
    });

    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: article.meta_description,
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              ...parentMeta,
              {
                "@type": "ListItem",
                position: "3",
                name: article.title,
                item: api + path,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: article.meta_keywords,
        },
        {
          vmid: "description",
          name: "description",
          content: article.meta_description,
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: img,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: article.meta_description,
        },
      ],
    };
  },
  components: {
    ShareIcon,
    TWIcon,
    FBIcon,
    VKIcon,
    ArrowIcon,
    EditorJSComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/article.styl"
@import "~@/styles/parts/news.styl"
</style>
