<template>
  <i class="vk-icon icon">
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6332 0.643563C15.7449 0.272277 15.6332 0 15.1046 0H13.3535C12.908 0 12.7044 0.235548 12.5926 0.49425C12.5926 0.49425 11.7023 2.66528 10.4407 4.07297C10.0335 4.48258 9.84747 4.61194 9.6255 4.61194C9.51451 4.61194 9.35322 4.48258 9.35322 4.1113V0.643563C9.35322 0.19802 9.22467 0 8.85338 0H6.10187C5.82401 0 5.65633 0.206004 5.65633 0.402427C5.65633 0.824016 6.28712 0.921429 6.35179 2.10955V4.68699C6.35179 5.25151 6.25039 5.35451 6.02682 5.35451C5.43356 5.35451 3.98993 3.1755 3.13318 0.681091C2.9663 0.196423 2.79782 0.000798788 2.35068 0.000798788H0.600446C0.0998082 0.000798788 0 0.236346 0 0.495049C0 0.959755 0.59326 3.25933 2.76349 6.30069C4.21031 8.3775 6.24719 9.50333 8.10282 9.50333C9.21509 9.50333 9.35242 9.25341 9.35242 8.82224V7.25246C9.35242 6.75262 9.45862 6.65201 9.81074 6.65201C10.0694 6.65201 10.515 6.78296 11.5538 7.78344C12.7403 8.96996 12.9367 9.50253 13.6035 9.50253H15.3537C15.8535 9.50253 16.1034 9.25261 15.9597 8.75916C15.8024 8.26811 15.2355 7.55348 14.4834 6.70791C14.0745 6.22563 13.4637 5.70663 13.2777 5.44713C13.0182 5.11257 13.0932 4.96486 13.2777 4.66783C13.2785 4.66863 15.4112 1.6648 15.6332 0.643563Z"
        fill="#2A3742"
      ></path>
    </svg>
  </i>
</template>

<script>
export default {
  name: "VKIcon",
};
</script>
